import React, { useEffect, useState } from "react";

import styles from "./PopUpExample.module.scss";

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PopUpExampleProps {
  popup: boolean;
  close: (e: boolean) => void;
  success: (e: boolean) => void;
  mode: string;
}

const PopUpExample: React.FC<PopUpExampleProps> = (props) => {
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    console.log(os);
    console.log(navigator.userAgent);
  });

  const os = (() => {
    if (/windows/i.test(navigator.userAgent)) {
      return "Windows";
    } else if (/iphone/i.test(navigator.userAgent)) {
      return "iOS";
    } else if (/ipad/i.test(navigator.userAgent)) {
      return "iOS";
    } else if (/macintosh/i.test(navigator.userAgent)) {
      return "Mac OS";
    }
    // more user agents to detect...
  })();

  return (
    <>
      <Modal
        show={props.popup}
        onHide={() => (setPopup(!popup), props.close)}
        className={`d-flex flex-column justify-content-end `}
        contentClassName={` ${styles.rounded}`}
      >
        <div
          className={`d-flex flex-column justify-content-center align-items-center  full bg-light-gray ${styles.rounded}`}
        >
          <Modal.Body
            className={`d-flex flex-column align-items-center  justify-content-center 
            bg-light
            `}
            style={{
              height: "50vh",
              width: "100vw",
              borderRadius: "10px",

              //   border: `2px solid ${props.mode === "LIGHT" ? "black" : "white"}`,
            }}
          >
            <div className="d-flex flex-row align-items-center justify-content-end col-12 mb-2 ">
              <span
                className={`${styles.closeBtn}`}
                onClick={() => props.close(false)}
              >
                <FontAwesomeIcon
                  icon={faXmark as IconProp}
                  style={{ fontSize: "1.5rem" }}
                />
              </span>
            </div>
            <div className={`${styles.videoSection}`}>
              <img src="https://d16qu8ed6p45v5.cloudfront.net/savecontact_businesscard.mp4" />
            </div>
            <div className=" col-12 mt-5 d-flex flex-row align-items-center justify-content-center">
              <div
                className={`d-flex flex-row align-items-center justify-content-center  col-6 ${styles.addButtonGreen}  `}
                onClick={() => props.success(false)}
              >
                Got it!
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default PopUpExample;
