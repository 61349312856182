import axios from "axios";

class axiosHelper {
  constructor() {
    this.setHeaders();
  }

  setHeaders() {
    axios.defaults.headers.common["Content-Type"] = "application/json";
  }

  getContactFile(name: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/businessCard`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getContact(uuid: string) {
    return axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/contact/${uuid}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}
export default new axiosHelper();
