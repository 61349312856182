import { createSelector } from "reselect";
import { IBusinessCardStore } from "./BusinessCardStore";
import { stat } from "fs";

const getBusinessCardState = (state: IBusinessCardStore) => state;

export const getCurrentContact = createSelector(
  getBusinessCardState,
  (state) => state.currentContact
);
