import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import BisazzaGolf from "./routes/BisazzaGolf/BisazzaGolf";

const Container = () => {
  return (
    <Routes>
      <Route path={`/contact/:name`} element={<Home />} />
      <Route path={`/BisazzaGolf/:name`} element={<BisazzaGolf />} />
    </Routes>
  );
};
export default Container;
